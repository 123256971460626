<template>
  <div style="background: #f4f6f9">
    <v-container>
      <div class="text-h5 font-weight-bold v-text-ctr my-3">Entrega</div>
    </v-container>
    <v-card class="mx-auto rounded-0 mb-3" elevation="0">
      <v-card-text>
        <div class="subtitle-1 font-weight-medium v-text-ctr">
          Tipo de entrega
        </div>
        <v-radio-group v-model="delivery.type" row>
          <v-radio
            class="v-delivery"
            label="Recojo en tienda"
            :value="0"
            color="#FF7D2D"
          ></v-radio>
          <v-radio
            class="v-delivery"
            label="Entrega a domicilio"
            :value="1"
            color="#FF7D2D"
          ></v-radio>
        </v-radio-group>
        <template v-if="delivery.type = 0">aaa</template>

        <p class="v-text-ctr">A partir del 23/04</p>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Fecha"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto rounded-0 mb-3" elevation="0">
      <v-card-text>
        <div class="subtitle-1 font-weight-medium v-text-ctr">
          Método de pago
        </div>
        <v-radio-group v-model="row">
          <v-radio
            label="Transferencia"
            value="transferencia"
            color="#FF7D2D"
          ></v-radio>
          <v-divider class="mt-3 mb-4"></v-divider>
          <v-radio
            label="Pago contraentrega"
            value="contraentrega"
            color="#FF7D2D"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card class="mx-auto rounded-0 mb-3" elevation="0">
      <v-card-text>
        <div class="subtitle-1 font-weight-medium v-text-ctr">
          Cupón de descuento
        </div>
        <v-text-field solo label="Ingresa código"
        prepend-inner-icon="mdi-ticket-percent-outline"></v-text-field>
      </v-card-text>
    </v-card>
    <div style="background:white;">
      <v-container>
        <table width="100%">
          <tr>
            <td class="grey--text py-2 subtitle-1">Subtotal</td>
            <td class="text-right grey--text py-2 subtitle-1">$140.000</td>
          </tr>
          <tr>
            <td class="grey--text py-2 subtitle-1">Descuento con cupón</td>
            <td class="text-right grey--text py-2 subtitle-1">- S/ 10.00</td>
          </tr>
          <tr>
            <td class="v-text-ctr py-2 fs-16 font-weight-medium">
              Monto final
            </td>
            <td class="text-right v-text-ctr py-2 fs-18 font-weight-medium">
              $130.000
            </td>
          </tr>
        </table>
        <v-btn
          color="#FF7D2D"
          white--text
          style="color: #ffffff"
          large
          block
          elevation="0"
          class="my-5"
          :to="'/payment'"
        >
          Confirmar pedido
        </v-btn>
      </v-container>
    </div>
  </div>
</template>
<style scoped></style>
<script>
import ModalConfirmOrder from "@/components/products/ModalConfirmOrder.vue";
export default {
  name: "Delivery",
  components: {
    ModalConfirmOrder,
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    disabled: false,
    readonly: false,
    delivery: {
      type: [0,1]
    }
  }),
};
</script>
