<template>
  <div class="home">
    <v-container style="background: #f4f6f9">
      <div class="text-h6 text-center font-weight-bold v-text-ctr mb-2">
        Selecciona la tienda de tu preferencia
      </div>

      <div class="mb-3">
        <v-text-field
          class="input-search"
          dense
          solo
          label="Selecciona tu ubicación"
          prepend-inner-icon="mdi-map-marker-outline"
          hide-details="auto"
          rounded
          style="border-radius: 20px"
        ></v-text-field>
      </div>

      <div class="mb-3">
        <v-text-field
          class="input-search"
          dense
          solo
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          hide-details="auto"
          rounded
          style="border-radius: 20px"
        ></v-text-field>
      </div>
      <div class="subtitle-1 font-weight-medium v-text-ctr mt-3">
        Tiendas disponible
      </div>
      <AllShops />
    </v-container>
  </div>
</template>
<style scoped>

</style>
<script>
import AllShops from "@/components/AllShops.vue";

export default {
  name: "Inicio",
  components: {
    AllShops,
  },
};
</script>
