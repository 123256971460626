<template>
    <v-container class="">
      <v-col
        class="mx-auto"
        sm="12"
        md="6"
        lg="4">
        <div class="text-h5 font-weight-bold v-text-ctr">
        Regístrate
        </div>
        <v-row class="pt-4">
        <v-col cols="12" md="6">
            <div class="fs-18 font-weight-medium v-text-ctr">Nombres</div>
            <v-text-field solo 
            hide-details></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
            <div class="fs-18 font-weight-medium v-text-ctr">Apellidos</div>
            <v-text-field solo hide-details></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
            <div class="fs-18 font-weight-medium v-text-ctr">Cédula de identidad</div>
            <v-text-field solo hide-details></v-text-field>
        </v-col>

        <v-col>
            <div class="fs-18 font-weight-medium v-text-ctr">Ocupación</div>
            <v-select
            ></v-select>
        </v-col>

        <v-col cols="12" md="6">
            <div class="fs-18 font-weight-medium v-text-ctr">Correo (opcional)</div>
            <v-text-field solo hide-details></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
            <div class="fs-18 font-weight-medium v-text-ctr">Celular</div>
            <v-text-field solo hide-details></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
            <div class="fs-18 font-weight-medium v-text-ctr">Contraseña*</div>
            <v-text-field solo hide-details></v-text-field>
            <div class="text-caption v-text-ctr">*Mínimo 8 carácteres que incluyan una mayúscula, 
                minúscula, número y carácter especial ($. %, #, etc)</div>
        </v-col>

        <v-col cols="12" md="12">
            <div class="fs-18 font-weight-medium v-text-ctr">Repetir contraseña*</div>
            <v-text-field solo hide-details></v-text-field>
        </v-col>
        <div
          align="center">
            <v-checkbox
            color="#FD4F1E"
            label="Acepto los términos y condiciones."
            ></v-checkbox>
        </div>
        </v-row>
        <div class="my-6">
          <v-btn
            color="#FF7D2D"
            block
            white--text
            large
            class="rounded-lg"
            style="color:#FFFFFF;"
            elevation="0"
            >
            Crear cuenta
          </v-btn>
        </div>
      </v-col>
    </v-container>
</template>

<script>

export default {
  name: 'Register',
  components: {
  }
}
</script>
