<template>
  <div>
    <div class="white--text pa-5 text-center" style="background: #001F5B">
      <v-img
        contain
        src="~@/assets/image11.png"
        width="108px"
        class="mx-auto pb-5"
      />
      <div class="text-h6 font-weight-bold mt-3">Juan Carlos Perales</div>
      <div class="subtitle-1 font-weight-regular" style="color:#FF7D2D;">Cliente VIP</div>
        <v-btn
          color="white"
          medium
          outlined
          elevation="0"
          rounded
          class="my-3"
          :to="'/'"
        >
          Mi cuenta
        </v-btn>
    </div>
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="deep-orange--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-car</v-icon>
          </v-list-item-icon>
          <v-list-item-title><v-btn :to="'/pedidos-activos'" text>Pedidos activos</v-btn> </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Historial de pedidos</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "AsideComponent",
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
