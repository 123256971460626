<template>
    <v-container class="">
      <v-col
        class="mx-auto"
        sm="12"
        md="6"
        lg="4">
        <div class="text-h4 font-weight-regular v-text-ctr">
        Recupera tu
        <span class="font-weight-bold">contraseña</span>
        </div>
        <div class="my-16">

          <v-text-field label="Celular" class="v-text-ctr"></v-text-field>
        </div>
        <div class="pb-3">
          <v-btn
            color="#FF7D2D"
            block
            white--text
            large
            class="rounded-lg"
            style="color:#FFFFFF;"
            elevation="0"
            >
            Recuperar contraseña
          </v-btn>
        </div>
      </v-col>
    </v-container>
</template>

<script>

export default {
  name: 'RecoverPassword',
  components: {
  }
}
</script>
