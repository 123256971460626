<template>
  <div style="background: #f4f6f9;min-height:80vh">
    <v-container>
      <div class="text-h5 font-weight-bold v-text-ctr my-3">Depósito</div>
      <p class="v-text-ctr">
        Por favor, inserte el número de voucher del depósito y una foto del
        mismo
      </p>
      <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
        Nº de voucher
      </div>
      <v-divider></v-divider>
      <div>
        <template>
          <v-file-input
            class="v-voucher-payment"
            show-size
            label="File input"
          ></v-file-input>
        </template>
        <v-card
          elevation="0"
          rounded="xl"
          class="v-card-voucher text-center mt-5"
        >
          <v-card-text class="">
            <v-img
              contain
              class="mx-auto"
              width="50px"
              src="~@/assets/camera.png"
            />
            <div class="subtitle-1 font-weight-bold v-text-ctr">
              Subir voucher
            </div>
            <div
              class="subtitle-1 font-weight-regular"
              style="color: #06284d; opacity: 0.5"
            >
              Adjunta o toma una foto
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <div class="v-bottom-absolute" style="background: white" bottom>
      <v-container>
        <table width="100%">
          <tr>
            <td class="grey--text subtitle-1">Subtotal</td>
            <td class="text-right grey--text subtitle-1">$140.000</td>
          </tr>
          <tr>
            <td class="grey--text subtitle-1">Descuento con cupón</td>
            <td class="text-right grey--text subtitle-1">- S/ 10.00</td>
          </tr>
          <tr>
            <td class="v-text-ctr py-2 fs-16 font-weight-medium">
              Monto final
            </td>
            <td class="text-right v-text-ctr py-2 fs-18 font-weight-medium">
              $130.000
            </td>
          </tr>
        </table>
        <v-btn
          color="#FF7D2D"
          white--text
          style="color: #ffffff"
          large
          block
          elevation="0"
          class="my-5"
          :to="'/'"
        >
          Confirmar pedido
        </v-btn>
      </v-container>
    </div>
  </div>
</template>
<style scoped>
.v-voucher-payment {
  z-index: 19;
  opacity: 0;
  position: absolute;
  width: 90%;
}
.v-card-voucher {
  width: 100%;
  z-index: 1;
  border: 1px solid #d5dae5;
  border-style: dashed;
}
</style>
<script>
export default {
  name: "VoucherPayment",
};
</script>
