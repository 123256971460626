<template>
  <div class="home" style="background: #f4f6f9">

    <v-container>      
      <div class="d-flex align-center justify-center">
        <v-text-field
          class="input-search"
          dense
          solo
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          hide-details="auto"
          rounded
          style="
    border-radius: 20px;"
        ></v-text-field>
      </div>
      <div class="text-h6 font-weight-bold v-text-ctr">Promociones</div>
    </v-container>
    <template>
      <v-carousel :show-arrows="false" hide-delimiters height="125px">
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
          cover
          class="rounded-lg"
        ></v-carousel-item>
      </v-carousel>
    </template>
    <v-container>
      <div class="d-flex align-center" align="center">
        <div class="text-h6 font-weight-bold v-text-ctr my-5">Categorías</div>
        <v-spacer></v-spacer>
        <v-btn
          :to="'/categorias'"
          class="font-weight-bold rounded-xl"
          outlined
          small
          color="#FF7D2D"
        >
          Ver todo
        </v-btn>
      </div>
      <v-row class="pt-4">
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/cement.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Cemento
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/tools.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/paint-bucket.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Pintura
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/power-plug.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Electricidad
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/XMLID_162_.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Herramien…
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <div class="d-flex align-center">
        <div class="text-h6 font-weight-bold v-text-ctr my-5">Productos</div>
        <v-spacer></v-spacer>
        <v-btn
          :to="'/products'"
          class="font-weight-bold px-10"
          outlined
          small
          color="#FF7D2D"
        >
          Ver todo
        </v-btn>
      </div>
      <AllProducts />
    </v-container>
    <v-container>
      <div class="text-h6 font-weight-bold v-text-ctr my-3">
        Servicios e información
      </div>
      <v-row>
        <v-col sm="6" lg="6" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/tienda1.png" height="59px" />
            <div class="v-text-ctr fs-16 font-weight-medium text-center pt-3">
              Nuestras tiendas
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              21 tiendas <br />
              afiliadas
            </div>
          </v-card>
        </v-col>
        <v-col sm="6" lg="6" md="6">
          <v-card class="rounded-0 pt-3" elevation="0">
            <v-img contain src="~@/assets/car.png" height="59px" />
            <div class="v-text-ctr fs-16 font-weight-medium text-center pt-3">
              Opciones de entrega
            </div>
            <div class="grey--text subtitle-1 text-center py-2">
              21 tiendas <br />
              afiliadas
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style scoped>
.v-image__image--cover {
  border-radius: 10px;
}
</style>
<script>
// @ is an alias to /src
import AllProducts from "@/components/products/AllProducts.vue";

export default {
  name: "Home",
  components: {
    AllProducts,
  },
  data() {
    return {
      items: [
        {
          src: require("../assets/banner.png"),
        },
      ],
    };
  },
};
</script>
