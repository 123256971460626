<template>
  <div>
    <v-container>
      <div class="text-h5 font-weight-bold v-text-ctr my-3">Depósito</div>
      <div
        class="subtitle-1 font-weight-bold v-text-ctr my-3"
        style="color: #ff7d2d"
      >
        Cuentas bancarias
      </div>
      <div class="d-flex align-center justify-space-between">
        <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
          Bancolombia 463-39638585-0-23
        </div>
        <v-btn color="#FF7D2D" small elevation="0" outlined rounded> Copiar </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="d-flex align-center justify-space-between">
        <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
          Davivienda 297-174068242-4-11
        </div>
        <v-btn color="#FF7D2D" small elevation="0" outlined rounded> Copiar </v-btn>
      </div>
      <v-divider></v-divider>
      <div class="d-flex align-center justify-space-between">
        <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
          BBVA 297-174068242-4-11
        </div>
        <v-btn color="#FF7D2D" small elevation="0" outlined rounded> Copiar </v-btn>
      </div>
      <div
        class="subtitle-1 font-weight-bold v-text-ctr my-3"
        style="color: #ff7d2d"
      >
        Wallets
      </div>
      <div class="d-flex align-center justify-space-between">
        <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
          315 376487567
        </div>
        <div>
          <v-btn class="mr-2" color="#2A93E4" small elevation="0" outlined rounded>
            Ver QR
          </v-btn>
          <v-btn color="#FF7D2D" small elevation="0" outlined rounded> Copiar </v-btn>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="d-flex align-center justify-space-between">
        <div class="subtitle-1 font-weight-bold v-text-ctr my-3">
          315 846589756
        </div>
        <div>
          <v-btn class="mr-2" color="#2A93E4" small elevation="0" outlined rounded>
            Ver QR
          </v-btn>
          <v-btn color="#FF7D2D" small elevation="0" outlined rounded> Copiar </v-btn>
        </div>
      </div>
      <v-btn
        color="#FF7D2D"
        white--text
        style="color: #ffffff"
        large
        block
        elevation="0"
        class="mt-16"
        rounded-xl
        :to="'/payment-2'"
      >
        Enviar voucher
      </v-btn>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Payment",
};
</script>
