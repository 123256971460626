<template>
  <div style="background: #f4f6f9;min-height:80vh">
    <div>
      <v-container>
      <div class="text-h5 font-weight-bold v-text-ctr my-3">Pedidos activos</div>
      <div>
          
      </div>
    </v-container>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "ActiveOrders",

};
</script>