<template>
<div style="background: #f4f6f9">
    <v-container>
    <div class="subtitle-1 font-weight-regular grey--text">Cemento</div>
    <div class="fs-18 font-weight-medium v-text-ctr">
      Cemento Quisqueya Zona Costera
    </div>
    <v-row>
      <v-col sm="6" md="6" lg="7">
        <v-img contain src="~@/assets/image5.png" height="600px" />
      </v-col>
      <v-col sm="6" md="6" lg="5">
        <v-card class="rounded-0 mb-4" elevation="0">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h4 font-weight-medium v-text-ctr mr-2">
                $ 50.000
              </div>
              <div
                class="text-h6 font-weight-regular grey--text text-decoration-line-through"
              >
                $ 150.000
              </div>
            </div>
            <div class="subtitle-1 font-weight-regular grey--text">
              Vendido por: nombre comercio
            </div>

            <v-btn
              color="#FF7D2D"
              small
              dark
              absolute
              top
              bottom
              right
              fab
              elevation="1"
            >
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
        <v-card class="rounded-0 mb-4" elevation="0">
          <v-card-text>
            <div class="fs-18 font-weight-medium v-text-ctr">Descripción</div>
            <div class="v-text-ctr mt-4">
              <p class="font-weight-regular" style="font-size: 15px">
                Para ser utilizado en la construcción de todo tipo de obras que
                no tengan requerimientos especiales de cemento. Para ser
                utilizado en la construcción de todo tipo de obras que no tengan
                requerimientos especiales de cemento.
              </p>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="rounded-0" elevation="0">
          <v-card-text>
            <div class="text-h5 font-weight-medium v-text-ctr">
              Opciones de entrega
            </div>
            <div class="mt-4 py-4">
              <v-row>
                <v-col lg="6">
                  <div class="d-flex flex-row">
                    <v-img
                      contain
                      src="~@/assets/tienda1.png"
                      class="mr-2"
                      width="42"
                    />
                    <div
                      class="v-text-ctr subtitle-1 text-left font-weight-regular"
                    >
                      Recojo en tienda
                    </div>
                  </div>
                </v-col>
                <v-col lg="6">
                  <div class="d-flex flex-row">
                    <v-img
                      contain
                      src="~@/assets/car.png"
                      class="mr-2"
                      width="42"
                    />
                    <div
                      class="v-text-ctr subtitle-1 text-left font-weight-regular"
                    >
                      Despacho a domicilio
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
    <div class="v-bottom-fixed">
      <v-container d-flex py-3 style="background:white">
        <div class="d-flex align-center">
          <v-btn class="mx-2" fab dark small color="#EEF2FF" elevation="0">
            <v-icon dark> mdi-minus </v-icon>
          </v-btn>
          <span class="fs-16 font-weight-medium v-text-ctr"> 1 </span>
          <v-btn class="mx-2" fab dark small color="#EEF2FF" elevation="0">
            <v-icon dark> mdi-plus </v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn
          color="#FF7D2D"
          white--text
          style="color: #ffffff"
          medium
          elevation="0"
          :to="'/resumen'"
        >
          <v-icon left> mdi-cart </v-icon>
          Añadir al carrito
        </v-btn>
      </v-container>
    </div>
</div>
</template>
<style scoped>
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top {
  top: 13px;
}
</style>
<script>
export default {
  name: "Product",
};
</script>
