<template>
    <v-container class="">
      <v-col
        class="mx-auto"
        sm="12"
        md="6"
        lg="4">
        <div class="text-h4 font-weight-regular v-text-ctr mb-12">
        Bienvenido a
        <span class="font-weight-bold">Construrama</span>
        </div>
        <div class="">

          <v-text-field label="Celular" class="v-text-ctr"></v-text-field>
          <v-text-field label="Contraseña"></v-text-field>
        </div>
        <div class="pb-3"
          align="end">
          <v-btn
            color="#FF7D2D"
            text
            class="font-weight-regular"
            :to="'/recover-password'"
            >
            Olvidé mi contraseña
          </v-btn>
        </div>
        <div class="pb-3">
          <v-btn
            color="#FF7D2D"
            block
            white--text
            large
            class="rounded-lg"
            style="color:#FFFFFF;"
            elevation="0"
        :to="'/inicio'"
            >
            Iniciar sesión
          </v-btn>
        </div>
        <div class="pb-3">
          <v-btn
            color="#F4F6F9"
            block
            white--text
            large
            class="rounded-lg"
            elevation="0"
            >
            <v-img
              alt="Vuetify Name"
              class="shrink mr-2"
              contain
              width="15"
              src="~@/assets/google.png"
            />
            Ingresar con Gmail
          </v-btn>
        </div>
        <div class="pb-3"
          align="center">
          <v-btn
            class="font-weight-regular"
            color="#001F5B"
            text
            white--text
        :to="'/register'"
            >
            ¿No tienes cuenta? <span class="font-weight-bold">Registrate</span>
          </v-btn>
        </div>
        <v-divider></v-divider>
        <div class="pt-3"
          align="center">
          <v-btn
            class="font-weight-bold"
            color="#001F5B"
            text
            white--text
        :to="'/register'"
            >
            Continuar como invitado
          </v-btn>
        </div>
      </v-col>
    </v-container>
</template>

<script>

export default {
  name: 'Login',
  components: {
  }
}
</script>
