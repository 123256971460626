<template>
    <div style="background: #f4f6f9">
    <v-container>
      <div class="mb-3">
        <v-text-field
          class="input-search"
          dense
          solo
          label="Buscar"
          prepend-inner-icon="mdi-magnify"
          hide-details="auto"
          rounded
          style="border-radius: 20px"
        ></v-text-field>
      </div>
      <v-row class="pt-4">
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/cement.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Cemento
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/tools.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Carpintería
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/paint-bucket.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Pintura
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/power-plug.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Electricidad
          </div>
        </v-col>
        <v-col class="px-0">
          <div
            class="white rounded-lg"
            elevation="2"
            style="
              width: 58px;
              height: 58px;
              filter: drop-shadow(0px 3px 6px rgba(51, 98, 123, 0.102));
            "
          >
            <v-img
              contain
              src="~@/assets/XMLID_162_.png"
              width="30px"
              class="mx-auto pb-5"
            />
          </div>
          <div
            class="text-caption text-center font-weight-medium v-text-ctr py-2"
          >
            Herramien…
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-tabs class="v-tabs-c">
        <v-tab style="width:50%">Cemento</v-tab>
        <v-tab style="width:50%">Promociones</v-tab>      
        <v-tab-item>
           <v-container>
                <AllProducts />
           </v-container>
        </v-tab-item>
    </v-tabs>
    </div>
</template>
<style scoped>
.v-tabs-c.v-tabs-slider-wrapper {
    color: #FE5000;
}
</style>
<script>
// @ is an alias to /src
import AllProducts from "@/components/products/AllProducts.vue";

export default {
  name: "Categories",
  components: {
    AllProducts,
  },
};
</script>