<template>
  <v-card class="mx-auto rounded-0" elevation="0">
    <v-list-item three-line>
      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 mr-3"
        contain
        max-width="95"
        src="~@/assets/image3.png"
      />
      <v-list-item-content>
        <div class="text-caption font-weight-regular grey--text">
          Precio unitario $35.000
        </div>
        <div class="d-flex align-center">
          <div class="d-flex align-center">
            <v-btn class="mx-2" fab dark small color="#EEF2FF" elevation="0">
              <v-icon dark> mdi-minus </v-icon>
            </v-btn>
            <span class="fs-16 font-weight-medium v-text-ctr"> 2 </span>
            <v-btn class="mx-2" fab dark small color="#EEF2FF" elevation="0">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </div>
          <div class="fs-18 font-weight-bold v-text-ctr ms-5">$70.000</div>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  name: "SummaryProductsComponent",
};
</script>
