<template>
  <v-app>
    <template>
        <v-navigation-drawer v-model="drawer" absolute temporary>
          <AsideComponent/> 
        </v-navigation-drawer>
        <v-app-bar elevation="0" dense app color="white">
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
          <div class="d-flex align-center mx-auto">
            <v-btn text :to="'/'" color="white">
              <v-img
                alt="Vuetify Name"
                class="shrink mt-3"
                contain
                min-width="100"
                src="~@/assets/Logo1.png"
              />
            </v-btn>
          </div>
        </v-app-bar>
    </template>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AsideComponent from "@/components/layout/AsideComponent.vue";
export default {
  name: "App",
  components: {
    AsideComponent,
  },
  data: () => ({
    drawer: false,
    group: null,
  }),
};
</script>
