<template>
  <v-container>
    <v-row>
      <v-col 
        v-for="n in 4"
        :key="n"
        cols="12"
        sm="12"
        md="4"
        lg="3"
        class="px-0"
      >
        <v-card
          class="mx-auto"
          max-width="344"
          elevation="0"
        >
          <v-list-item three-line>
              <v-img
                alt="Vuetify Name"
                class="shrink mt-1 mr-3"
                contain
                min-width="100"
                src="~@/assets/tienda.png"
              />
            <v-list-item-content>
              <div class="subtitle-1 font-weight-bold v-text-ctr mb-2">
                Construrama
              </div>
              <v-row
                align="center"
                class="mx-0"
              >
                <v-rating
                  :value="1"
                  color="#FF7D2D"
                  dense
                  half-increments
                  readonly
                  size="14"
                  empty-icon
                ></v-rating>

                <div class="grey--text ms-4 text-caption">
                  4.7 estrellas
                </div>
              </v-row>
              
              <div class="text-caption font-weight-medium v-text-ctr">
                Categoría de tienda
              </div>

              <v-row
                align="center"
                class="mx-0 my-1"
              >
                <div class="v-text-ctr text-caption" style="line-height: .75rem;">
                <v-icon class="fs-16">mdi-map-marker-outline</v-icon>
                  Cl. 56 - 567 - Teusaquillo
                  Bogotá
                </div>
              </v-row>
            </v-list-item-content>
            <v-btn :to="'/home/'"
            class="pa-0"
            text>           
                <v-icon color="#FF7D2D">mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AllShops",
};
</script>