<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#FF7D2D"
          white--text
          style="color: #ffffff"
          large
          block
          elevation="0"
          class="my-5"
          v-bind="attrs"
          v-on="on"
        >
          Confirmar pedido
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 text-center v-text-ctr justify-center">
          Comprar y pagar
        </v-card-title>

        <v-card-text class="text-center v-text-ctr">
            Para continuar con la compra debes registrarte o iniciar sesión
        </v-card-text>

        <v-card-actions class="flex-column py-4">         
          <v-btn
            color="#FF7D2D"
            large
            block
            elevation="0"
            outlined
            class="mb-4"
            :to="'/register'"
            >
            Registrarme
            </v-btn>
          <v-btn
            color="#FF7D2D"
            white--text
            style="color: #ffffff"
            large
            block
            elevation="0"
            :to="'/delivery'"
            >
            Iniciar sesión
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
    color: #001F5B;
}
</style>
<script>
export default {
  name: "ModalConfirmOrder",
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
