import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import RecoverPassword from '../views/auth/RecoverPassword.vue'
import Register from '../views/auth/Register.vue'
import Inicio from '../views/Inicio.vue'
import Categories from '../views/Categories.vue'
import Product from '../views/Product.vue'
import Resumen from '../views/Resumen.vue'
import Delivery from '../views/Delivery.vue'
import Payment from '../views/Payment.vue'
import VoucherPayment from '../views/VoucherPayment.vue'
import ActiveOrders from '../views/ActiveOrders.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: RecoverPassword
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/inicio',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/categorias',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/resumen',
    name: 'Resumen',
    component: Resumen
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/payment-2',
    name: 'VoucherPayment',
    component: VoucherPayment
  },
  {
    path: '/pedidos-activos',
    name: 'ActiveOrders',
    component: ActiveOrders
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
