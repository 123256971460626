<template>
  <div style="background: #f4f6f9;min-height:80vh">
    <div>
      <v-container>
      <div class="text-h5 font-weight-bold v-text-ctr my-3">Resumen</div>
    </v-container>
          <SummaryProductsComponent/>
    </div>
    <div class="v-bottom-absolute" style="background: white">
      <v-container>
        <table width="100%">
          <tr>
            <td class="grey--text py-2 subtitle-1">Subtotal</td>
            <td class="text-right grey--text py-2 subtitle-1">$140.000</td>
          </tr>
          <tr>
            <td class="grey--text py-2 subtitle-1">Descuento con cupón</td>
            <td class="text-right grey--text py-2 subtitle-1">- S/ 10.00</td>
          </tr>
          <tr>
            <td class="v-text-ctr py-2 fs-16 font-weight-medium">
              Monto final
            </td>
            <td class="text-right v-text-ctr py-2 fs-18 font-weight-medium">
              $130.000
            </td>
          </tr>
        </table>
        <ModalConfirmOrder/>
      </v-container>
    </div>
  </div>
</template>
<script>
import SummaryProductsComponent from "@/components/products/SummaryProductsComponent.vue";
import ModalConfirmOrder from "@/components/products/ModalConfirmOrder.vue";

export default {
  name: "Resumen",
    components: {
    SummaryProductsComponent,
    ModalConfirmOrder,
  },
};
</script>
